import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Radio buttons are used for mutually exclusive choices, not for multiple choices. Only one radio button can be selected at a time. When a user chooses a new item, the previous choice is automatically deselected.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/guideline-1.svg",
      "alt": "radio button guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Indicator Selector :`}</strong>{` Selection control (input) that can be selected or unselected, and pushed into different states`}</li>
      <li parentName="ol"><strong parentName="li">{`Label :`}</strong>{` The Label is the text attributed to the Checkbox that provides context`}
        <br />
      </li>
    </ol>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/guideline-2.svg",
      "alt": "radio button guideline"
    }}></img>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Use radio buttons when you want users to see all available options and the list of options is small. For mutually exclusive options, consider a checkbox or switch.`}</p>
    <div>
  <ul>
    <li> Radio buttons are best for six or fewer options.</li>
    <li>
      {" "}
      For more than 6 options, consider a select, which prompts users to disclose
      the options
    </li>
  </ul>
    </div>
    <p><strong parentName="p">{`Common places radio button appear :`}</strong>{` form, confirmation, etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> When You Want to Emphasize Options</li>
      <li> When You Have Less Than 5 Options</li>
      <li> When Comparison of Options Needs to Make Clear</li>
      <li> When Visibility and Quick Response Is A Priority</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>When Default Option Is The Recommended Option</li>
      <li>When A Large Number of Familiar Options Are Available</li>
      <li>When You Have More Than 7 Options </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Radio buttons are an essential element of forms. They are used when there is a list of two or more options that are mutually exclusive and the user must select exactly one choice. Clicking a non-selected radio button will deselect whatever other button was previously selected in the list.`}</p>
    <p>{`Radio buttons are great when used correctly they simplify the task of selecting an option. In this article, we’ll overview practical guidelines for radio buttons that have been crafted from usability testing.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Lesser options are good to place side by side, so use Radio Button when you have less than 5 Option" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/guideline-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Avoid nesting radio buttons with other radio buttons or checkboxes make avoid confusion." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/guideline-4.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use vertical alignment for user selected" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/guideline-5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use horizontal alignment for user selected make confused" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/guideline-6.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use radio buttons when only one item can be selected" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/guideline-7.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Don't use checkboxes when only one item can be selected" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/guideline-8.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className='divi' />
    <h2>{`Content`}</h2>
    <p>{`When creating Radio Button :`}</p>
    <ul>
      <li parentName="ul">{`Make sure that the purpose and current state of a set of radio buttons is explicit.`}</li>
      <li parentName="ul">{`Limit the radio button’s text label to a single line.`}</li>
      <li parentName="ul">{`If the text label is dynamic, consider how the button will automatically resize and what will happen to any visuals around it.`}</li>
      <li parentName="ul">{`Use the default font unless your brand guidelines tell you otherwise.`}</li>
      <li parentName="ul">{`Don’t put two RadioButtons groups side by side. When two RadioButtons groups are right next to each other, it can be difficult for users to determine which buttons belong to which group.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      